<script setup lang="ts">
    import { useGlobalProperties } from '@/hooks/opshellLibary';
    import useApi from '@/hooks/useApi';

    import piniaStore from '@/store';

    const userStore = piniaStore.useUserStore;
    const { userState } = storeToRefs(userStore);
    const proxy = useGlobalProperties();

    const route = useRoute();
    const router = useRouter();

    const { sendRequest } = useApi();

    function logoutHandler() {
        proxy.$notify('warning', '提醒！', '您確定要登出洗腎護照嗎?', 0, true).then(() => {
            const url = `/api/logout`;

            sendRequest(url, 'POST').then((_auth) => {
                userStore.signOut();
                userStore.clearLogoutTimer();
                router.push('/login');
            }).catch((error) => {
                console.error(error);
            });
        }).catch((error) => {
            console.error(error);
        });
    }

    const isDEV = import.meta.env.DEV;

    function goHome() {
        router.push({
            path: '/patient-list/1',
            query: {}
        });
    }
</script>

<template>
    <header class="headerBlock">
        <div class="logoBox">
            <ElImg class="tzuchi" src="/assets/images/TzuChi_logo.png" alt="慈濟醫院" />
            <span class="x">X</span>
            <ElImg class="ncku" src="/assets/images/ncku_logo.png" alt="國立成功大學" />
            <ElImg class="ai4dt" src="/assets/images/ai4dt_logo.png" alt="創新數位內容研究中心" />
        </div>
        <div class="iconBox">
            <div v-if="isDEV" class="iconGroup" :class="{ current: route.matched[0].path === '/develop' }">
                <ElSvgIcon href="/develop" name="settings" />
                <div class="iconChild">
                    <ElSvgIcon href="clear-test-page" name="deployed_code" :class="{ current: route.matched[1]?.name === 'CTP' }" />
                    <ElSvgIcon href="ui-ux-set" name="qr_code_2" :class="{ current: route.matched[1]?.name === 'UIUXSet' }" />
                    <ElSvgIcon href="icon-list" name="dns" :class="{ current: route.matched[1]?.name === 'IconList' }" />
                </div>
            </div>

            <ElSvgIcon
                class="btn" name="home"
                @click="goHome()"
            />

            <ElSvgIcon v-if="userState.isLogin" class="btn" name="logout" @click="logoutHandler" />
        </div>
    </header>
</template>

<style lang="scss">
    .headerBlock {
        @include setFlex(space-between);
        @extend %scope;
        width: 100%;
        padding: 20px 0;

        .logoBox {
            @include setFlex(flex-start, center);
            .x {
                margin: 0 20px;
                color: $colorFont;
                font-size: 30px;
            }
            @include setRWD(1024px) {
                .x {
                    margin: 0 5px 0 10px;
                    font-size: 20px;
                }
                .img { object-fit: none; }
                .tzuchi {
                    @include setSize(70px, 40px);
                    object-position: -2px center;
                }
                .ncku {
                    @include setSize(45px, 45px);
                    object-position: -1px center;
                }
                .ai4dt {
                    @include setSize(50px, 50px);
                    object-position: -13px center;
                }
            };
        }

        .iconBox {
            display: flex;
            gap: 15px;
        }
        .icon {
            background: #fff;
            @include setSize(50px, 50px);
            padding: 8px;
            border-radius: 50%;
            fill: $colorSubs;
        }
        @include setRWD(1024px) {
            padding-top: 20px;
            padding-bottom: 20px;
        };
        @include setRWD(600px) {
            padding-top: 20px;
            padding-bottom: 20px;
        };
    }

    .iconGroup {
        @include setFlex(center, center, 0, row-reverse);
        background: $colorFont;
        border-radius: 25px;

        &.current {
            .iconChild {
                max-width: 180px;
                padding: 0 3px 0 8px;
                .icon {
                    opacity: 1;
                }
            }
        }
        .iconChild {
            @include setFlex();
            max-width: 0;
            padding: 0;
            box-shadow: $shadow-in;
            transition: max-width .15s $cubic-FiSo;
            overflow: hidden;
            .icon {
                flex-shrink: 0;
                background: $colorFont;
                fill: #eee;
                opacity: 0;
            }
        }
    }
</style>
