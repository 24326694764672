<script setup lang="ts">

</script>

<template>
    <div class="loadingOverlay">
        <div class="spinner" />
    </div>
</template>

<style lang="scss">
    .loadingOverlay {
        @include setFlex();
        position: absolute;
        inset: 0;
        background: rgba(255, 255, 255, 80%);
        @include setSize(100%, 100%);
        backdrop-filter: blur(8px);
        .spinner {
            @include setSize(40px, 40px);
            border: 4px solid #f3f3f3;
            border-top: 4px solid $colorMain;
            border-radius: 50%;
            animation: circle 1s linear infinite;
        }
    }
</style>
